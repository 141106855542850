import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import { TranslateService } from '@ngx-translate/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Observable, Subject, Subscription, distinctUntilChanged, takeUntil, tap } from 'rxjs';
import { environment } from '../../../../src/environments/environment';
import { AppSectionTemplateService } from '../../services/section-template.service';

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
	langSub: Subscription;
	lang$: Observable<string>;

	@Input('isMobileView') isMobileView: boolean = false;
	@Input('form') form: any = null;
	// @Input('selectedLanguage') selectedLanguage: string = '';
	selectedLanguage: string = 'de';
	coordinate: any = { lat: 47.448948, lng: 8.567236 };

	contactBodyForm: any = null;
	locationForm: string = null;
	phoneForm: string = null;
	mailForm: string = null;
	latitudeForm: any = null;
	longitudeForm: any = null;
	isCookieConsentAllow: boolean = true;
	isMicrosite: boolean = false;

	private _unSubscribeAll: Subject<any>;
	private statusChangeSubscription!: Subscription;
	map: google.maps.Map;

	styleArray = [
		{
			elementType: 'geometry',
			stylers: [
				{
					color: '#f5f5f5',
				},
			],
		},
		{
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#616161',
				},
			],
		},
		{
			elementType: 'labels.text.stroke',
			stylers: [
				{
					color: '#f5f5f5',
				},
			],
		},
		{
			featureType: 'administrative.land_parcel',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#bdbdbd',
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'geometry',
			stylers: [
				{
					color: '#eeeeee',
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#757575',
				},
			],
		},
		{
			featureType: 'poi.park',
			elementType: 'geometry',
			stylers: [
				{
					color: '#e5e5e5',
				},
			],
		},
		{
			featureType: 'poi.park',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#9e9e9e',
				},
			],
		},
		{
			featureType: 'road',
			elementType: 'geometry',
			stylers: [
				{
					color: '#ffffff',
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#757575',
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry',
			stylers: [
				{
					color: '#dadada',
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#616161',
				},
			],
		},
		{
			featureType: 'road.local',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#9e9e9e',
				},
			],
		},
		{
			featureType: 'transit.line',
			elementType: 'geometry',
			stylers: [
				{
					color: '#e5e5e5',
				},
			],
		},
		{
			featureType: 'transit.station',
			elementType: 'geometry',
			stylers: [
				{
					color: '#eeeeee',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'geometry',
			stylers: [
				{
					color: '#c9c9c9',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#9e9e9e',
				},
			],
		},
	];
	mapLinkText: string = '';
	locationNotFoundText: string = '';
	outletName: any;
	getTranslations$: Observable<any>;
	currentMicroSiteLanguage: string = 'de';

	constructor(
		private appSectionTemplateService: AppSectionTemplateService,
		private translationService: TranslateService,
		private _router: Router // private ccService: NgcCookieConsentService,
	) // private cookieService: SsrCookieService
	{
		this._unSubscribeAll = new Subject();
	}

	ngOnInit(): void {
		this.isMicrosite = !(
			this._router.url.includes('template-preview') ||
			this._router.url.includes('preview') ||
			this._router.url.includes('microsites')
		);
		this.getTranslations();
	}

	getTranslations(): void {
		if (this.isMicrosite) {
			this.currentMicroSiteLanguage = this.translationService.currentLang;
			this.getTranslations$ = this.translationService.get(['VIEW_IN_GOOGLE_MAPS', 'LOCATION_NOT_FOUND']).pipe(
				takeUntil(this._unSubscribeAll),
				distinctUntilChanged(),
				tap((res) => {
					this.selectedLanguage = this.currentMicroSiteLanguage;
					this.mapLinkText = res['VIEW_IN_GOOGLE_MAPS'];
					this.locationNotFoundText = res['LOCATION_NOT_FOUND'];
					this.proccessContactData();
				})
			);
			this.proccessContactData();
		} else {
			this.translationService
				.get(['VIEW_IN_GOOGLE_MAPS', 'LOCATION_NOT_FOUND'])
				.pipe(takeUntil(this._unSubscribeAll))
				.subscribe((res: string[]) => {
					this.proccessContactData();
					this.processMapData();
					this.mapLinkText = res['VIEW_IN_GOOGLE_MAPS'];
					this.locationNotFoundText = res['LOCATION_NOT_FOUND'];
				});
		}
	}

	onEnableMap() {
		this.isCookieConsentAllow = true;
		this.processMapData();
		//this.cookieService.set('cookieconsent_status', 'allow', 2, '/', environment.cookieDomain);
	}

	processMapData() {
		let loader = new Loader({
			apiKey: environment.MapApiAccessKey,
			language: this.selectedLanguage,
		});

		loader.load().then(() => {
			this.map = new google.maps.Map(document.getElementById('map'), {
				center: this.coordinate,
				zoom: 15,
				styles: this.styleArray,
			});

			const icon = {
				url: 'https://az-cdn.selise.biz/selisecdn/cdn/sv-cms/map-marker.svg', // url
				scaledSize: new google.maps.Size(70, 70), // scaled size
				// origin: new google.maps.Point(0, 0), // origin, we might need later
				// anchor: new google.maps.Point(0, 0), // anchor, we might need later
			};

			const marker = new google.maps.Marker({
				position: this.coordinate,
				map: this.map,
				icon: icon,
			});

			const content =
				'<div id="content">' +
				'<div id="siteNotice">' +
				'</div>' +
				'<div id="bodyContent">' +
				`<p><strong>${this.outletName}</strong></p>` +
				`${
					this.locationForm
						? `${this.generateSingleColumnAddressHTML(this.locationForm)}`
						: `<p class="notFound">${this.locationNotFoundText}</p>`
				}` +
				'<p class="gap"></p>' +
				`<a class="linkText" target="_blank" href=https://www.google.com/maps/search/?api=1&query=${this.coordinate.lat},${this.coordinate.lng}>` +
				`${this.mapLinkText} </a>` +
				'</div>' +
				'</div>';

			const infoWindow = new google.maps.InfoWindow({
				content: content,
			});
			marker.addListener('click', () => {
				infoWindow.open({ anchor: marker, map: this.map });
			});
		});
	}

	proccessContactData() {
		if (this.form != null) {
			this.outletName = this.form.OutletMetadata?.value?.OutletName || this.translationService.instant('OUTLET_NAME');
			this.selectedLanguage = this.form?.Language?.value || this.translationService.currentLang;
			this.contactBodyForm = this.form.OutletMetadata?.value?.ContactUsBodyText;
			this.locationForm = this.form.OutletMetadata?.value?.HoldingAddress;
			this.phoneForm = this.form.OutletMetadata?.value?.OutletPhoneNumber;
			this.latitudeForm = this.form.OutletMetadata?.value?.GeolocationLatitude;
			this.longitudeForm = this.form.OutletMetadata?.value?.GeolocationLongitude;
			this.mailForm = this.form?.OutletMetadata?.value?.OutletEmail;
			this.appSectionTemplateService.watchAndMarkSectionWhileEdit(this.form?.IsOnEdit, 'contact-us');
		}

		if (this._router.url.includes('template-preview')) {
			if (!this.contactBodyForm) this.contactBodyForm = 'EMAIL_OR_TELEPHONE';
			if (!this.locationForm) this.locationForm = '8058 Zurich Airport';
			if (!this.phoneForm) this.phoneForm = '079 903 46 44';
			if (!this.mailForm) this.mailForm = 'circle@sv-group.ch';
			if (!this.latitudeForm) this.latitudeForm = 47.448948;
			if (!this.longitudeForm) this.longitudeForm = 8.567236;
		}

		//this.setCookieConsent();
		this.coordinate = { lat: this.latitudeForm, lng: this.longitudeForm };
		this.processMapData();
	}

	setCookieConsent(): void {
		// if (!(this.latitudeForm == null || this.longitudeForm == null) && this.isMicrosite) {
		// 	this.coordinate = { lat: this.latitudeForm, lng: this.longitudeForm };
		// 	if (this.cookieService.get('cookieconsent_status') == 'allow') {
		// 		this.isCookieConsentAllow = true;
		// 		this.processMapData();
		// 	}
		// 	this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
		// 		if (event.status === 'allow') {
		// 			this.isCookieConsentAllow = true;
		// 			this.processMapData();
		// 		}
		// 	});
		// }
	}

	generateSingleColumnAddressHTML(address: string): string {
		const addressSegments = address.split(',');
		let outputHTML = addressSegments.join('</p><p>');
		outputHTML = `<p>${outputHTML}</p>`;
		return outputHTML;
	}

	ngOnDestroy() {
		this._unSubscribeAll.unsubscribe();
	}
}
