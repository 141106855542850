<ng-container *ngIf="getTranslations$ | async"></ng-container>
<div
	id="contact-us"
	class="container section-padding"
	ngClass.xs="mobile-display"
	[class.mobile-display]="isMobileView"
	[class.is-edit]="this.form?.IsOnEdit?.value"
	*ngIf="locationForm || phoneForm || mailForm || (latitudeForm && longitudeForm)"
>
	<div class="sections" fxLayout="row" fxLayout.lt-md="column">
		<div
			class="about-section"
			fxFlex="50"
			ngClass.xs="mobile-display"
			fxFlex.lt-md="100"
			*ngIf="locationForm || phoneForm || mailForm"
		>
			<div class="section-content">
				<p class="contact-text">{{ 'CONTACT' | translate }}</p>
				<h1 class="contact-description" class="h1-48px">{{ 'HOW_CAN_REACH' | translate }}</h1>
				<p class="brief-text" [innerHTML]="contactBodyForm | translate"></p>
				<div class="contact-information">
					<h3 class="contact-info-text">{{ 'CONTACT_INFORMATION' | translate }}</h3>
					<div fxLayout="column" fxLayoutGap="18px">
						<div class="flex align-center" fxLayoutGap="16px" *ngIf="locationForm">
							<img src="../../../assets/icons/location2.svg" alt="location-icon" />
							<ng-container>
								<p [innerHTML]="locationForm"></p>
							</ng-container>
						</div>
						<div class="flex align-center" style="cursor: pointer" fxLayoutGap="16px" *ngIf="phoneForm">
							<img src="../../../assets/icons/phone2.svg" alt="phone-icon" />
							<ng-container>
								<p>
									<a href="tel:{{ phoneForm }}">{{ phoneForm }}</a>
								</p>
							</ng-container>
						</div>
						<div class="flex align-center" style="cursor: pointer" fxLayoutGap="16px" *ngIf="mailForm">
							<img src="../../../assets/icons/mail2.svg" alt="mail-icon" />
							<ng-container>
								<p class="email">
									<a href="mailto:{{ mailForm }}">{{ mailForm?.replace('@', '[at]') }}</a>
								</p>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			*ngIf="(isCookieConsentAllow || !isMicrosite) && !(latitudeForm == null || longitudeForm == null)"
			class="map-section"
			fxFlex="{{ locationForm || phoneForm || mailForm ? 50 : 100 }}"
			ngClass.xs="mobile-display"
			id="map"
			fxFlex.lt-md="100"
		></div>
		<div
			*ngIf="!isCookieConsentAllow && isMicrosite"
			(click)="onEnableMap()"
			class="privacy-wrapper"
			fxFlex
			fxLayout="column"
			fxLayoutAlign="center center"
		>
			<div class="privacy-placeholder" fxFlex="30%" fxLayout="column" fxLayoutAlign="center center">
				<div class="privacey-label">{{ 'MAP_PERMISSION' | translate }}</div>
				<button mat-flat-button class="button">{{ 'ACCEPT_BUTTON' | translate }}</button>
			</div>
		</div>
	</div>
</div>

<!-- <ng-template #notFound>
	<p class="disableText">
		{{ 'NOT_FOUND' | translate }}
	</p>
</ng-template> -->
