import { Component, OnInit } from '@angular/core';
import { SplashScreenService } from 'src/app/shared/services/splash-screen.service';

@Component({
	selector: 'app-root-default',
	templateUrl: './root-default.component.html',
	styleUrls: ['./root-default.component.scss'],
})
export class RootDefaultComponent implements OnInit {
	constructor(private _splashScreenService: SplashScreenService) {}

	ngOnInit(): void {}
}
